$(document).on('ready turbolinks:load', function() {

  $(".phone_num").keyup(function (e) {
     console.log("cusom js phone keyup>>>>>>>>>>")
      let text=$(this).val()                             //Get the value
      text=text.replace(/\D/g,'')                        //Remove illegal characters 
      if(text.length>3) text=text.replace(/.{3}/,'$&-')  //Add hyphen at pos.4
      if(text.length>7) text=text.replace(/.{7}/,'$&-')  //Add hyphen at pos.8
      $(this).val(text); 
  });

  $(".date_check").keyup(function (e) {
    let userDate=$(this).val();                        //Get the value
    let ToDate = new Date();
    if (new Date(userDate).getTime() <= ToDate.getTime()) {
      alert("The Date must be Bigger or Equal to today date");
      return false;
    }
    return true;
  });


  var moretext = "Show More";
  var lesstext = "Show Less";

  check_blog_lines()

  $(window).resize(function(){
    check_blog_lines();
  });

  $("body").on('click', '.morepaths',function(){
    var more_path = $(this)
    if(more_path.hasClass("less")) {
      more_path.prev('.blog-content').addClass('moretext');
      more_path.removeClass("less");
      more_path.html(moretext);
    } else {
      more_path.prev('.blog-content').removeClass('moretext');
      more_path.addClass("less");
      more_path.html(lesstext);
    }
    return false;
  });
});

function check_blog_lines() {
  $('.trix-content').each(function(index, val){
    $(val).children('div').attr('id', "blog_"+index )
    el = document.getElementById("blog_"+index)
    el.style.lineHeight = "30px"
    divHeight = el.offsetHeight
    lineHeight = parseInt(el.style.lineHeight);
    lines = divHeight / lineHeight;
    button = $(this).parent('.blog-content').parent('.media-body').find('.morepaths')
    if (lines <= 5) {
      if (screen.width >= 576 && screen.width <= 767 && lines > 2 ) {
        button.show();
      }
      else {
        button.hide();
      }
    }

    else {
      button.show();
    }
  })

}